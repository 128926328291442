<template>
  <div class="container">
    <input
      :disabled="disabled"
      type="checkbox"
      v-model="value"
      :class="{ disabled: disabled }"
    />
    <label class="checkmark">{{ label }}</label>
  </div>
</template>

<script type="module">
export default {
  name: "Checkbox",
  props: {
    label: String,
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required:false,
      default: false,
    }
  },
  watch: {
    value (value) {
      this.$emit('input', value)
    }
  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.container label {
  margin: 0px 10px;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-top: 3px;
  height: 0;
}

input[type="checkbox"]:before {
  background-color: white;
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 3px;
  border: 1px solid rgba(200, 200, 200, 0.5);
}
.disabled:before{
  background-color: #cdcdcd!important;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #cf0209;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 6px;
}
</style>
